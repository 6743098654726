// scss/elements/_webcam-capture.scss

.mirror {
    transform: scaleX(-1);
}

.webcam-container {
    position: relative;
    width: 100%;
    height: auto;
    margin: auto;
}

#webcam {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

.button-19 {
    padding: 0.6em 1.4em;
    border-radius: 5px;
    background-color: #f39c12;
    color: white;
    border: none;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button-19:hover {
    background-color: #e67e22;
}
