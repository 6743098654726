.webcam-capture {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  .webcam-container {
    width: 640px;
    height: 480px;
    border: 2px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .webcam-container video {
    width: 100%;
    height: 100%;
  }
  
  .webcam-container .mirror {
    transform: scaleX(-1);
  }
  
  .btn-default {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .btn-default:disabled {
    background-color: #ccc;
  }
  
  .btn-large {
    font-size: 18px;
    padding: 15px 30px;
  }
  
  .verification-page {
    padding: 20px;
  }
  
  .verification-page .title {
    font-size: 32px;
    margin-bottom: 20px;
  }
  