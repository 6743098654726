/*--------------------------
    split Area Styles  
----------------------------*/

.rainbow-splite-style {
    .thumbnail {
        img {
            border-radius: 0;
            width: 100%;
        }
        &.image-left-content {
            img {
                border-radius: 0 10px 10px 0;
            }
        }
    }
    .split-inner {
        padding: 30px 115px;

        @media #{$lg-layout} {
            padding: 40px 80px;
        }
        @media #{$md-layout} {
            padding: 40px 30px;
        }
        @media #{$sm-layout} {
            padding: 40px 30px;
        }
        
        .title {
            font-size: 36px;
            line-height: 50px;
            color: var(--color-heading);
            font-weight: var(--p-bold);
            @media #{$sm-layout} {
                font-size: 24px;
                line-height: 30px;
            }
        }
        .split-list {
            list-style: none;
            padding-left: 0;
            margin: 0;
            li {
                color: var(--color-dark-alt);
            }
        }
        .features-section{  
            .single-features{
                display: flex;
                align-items: center;
                margin: 5px 0;
                .icon{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 34px;
                    width: 34px;
                    line-height: 34px;
                    font-size: 20px;
                    margin-right: 10px;
                }
                p{
                    margin-bottom: 0;
                }
            }
        }
    }
}
