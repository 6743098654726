.inner {
    &.Collaborate-inner {
        padding: 0 50px 0 30px ;
        @media #{$sm-layout} {
            padding: 30px 0;
        }

        .title {

        }
        .description {
            @media #{$md-layout} {
                font-size: 14px;
            }
        }

    }
}



  